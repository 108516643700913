
import { ref, reactive, defineComponent } from "vue"
import { ElForm } from "element-plus"
import ApiService from "@/core/services/ApiService"
import { InternalRuleItem } from "async-validator"

export default defineComponent({
  name: "Password",
  components: {},
  setup() {
    type FormInstance = InstanceType<typeof ElForm>
    const ruleFormRef = ref<FormInstance>()
    const ruleForm = ref({
      newPassword: "",
      currentPassword: "",
      checkNewPassword: "",
    })
    const checkPassword = ref("")

    const passwordPolicyFormat = new RegExp(/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)[A-Za-z\d]{8,}$/)

    const validateCurrentPass = (
      rule: InternalRuleItem,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      value: any,
      callback: (error?: string | Error | undefined) => void
    ) => {
      if (value === "") callback(new Error("Lütfen Eski Şifre Giriniz!"))
      else {
        callback()
      }
    }

    const validatePass = (
      rule: InternalRuleItem,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      value: any,
      callback: (error?: string | Error | undefined) => void
    ) => {
      if (value === "") callback(new Error("Lütfen Yeni Şifre Giriniz!"))
      else if (value != ruleForm.value.checkNewPassword)
        callback(new Error("Şifreler Aynı Olmalıdır"))
      else if (!passwordPolicyFormat.test(value))
        callback(new Error("En az 8 karakter olmalı, 1 büyük harf ve 1 rakam içermelidir!"))
      else {
        if (ruleForm.value.checkNewPassword != "" && ruleFormRef.value)
          ruleFormRef.value.validateField("checkNewPassword", () => null)

        callback()
      }
    }

    const validateCheckPass = (
      rule: InternalRuleItem,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      value: any,
      callback: (error?: string | Error | undefined) => void
    ) => {
      if (value === "") callback(new Error("Lütfen Yeni Şifrenizi Tekrar Giriniz!"))
      else if (value != ruleForm.value.newPassword) callback(new Error("Şifreler Aynı Olmalıdır"))
      else if (!passwordPolicyFormat.test(value))
        callback(new Error("En az 8 karakter olmalı, 1 büyük harf ve 1 rakam içermelidir!"))
      else {
        if (ruleForm.value.newPassword != "" && ruleFormRef.value)
          ruleFormRef.value.validateField("newPassword", () => null)
        callback()
      }
    }

    const rules = reactive({
      currentPassword: [{ validator: validateCurrentPass, trigger: "blur" }],
      newPassword: [{ validator: validatePass, trigger: "blur" }],
      checkNewPassword: [{ validator: validateCheckPass, trigger: "blur" }],
    })

    const submitForm = formEl => {
      if (!formEl) return
      formEl.validate(async valid => {
        if (valid) {
          const config = {
            newPassword: ruleForm.value.newPassword,
            currentPassword: ruleForm.value.currentPassword,
          }
          await ApiService.post("auth/change-password", config)
          resetForm(formEl)
        }
      })
    }

    const resetForm = formEl => {
      if (!formEl) return
      formEl.resetFields()
    }

    return {
      ruleFormRef,
      ruleForm,
      resetForm,
      submitForm,
      rules,
      checkPassword,
    }
  },
})
