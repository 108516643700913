
import { ref, defineComponent, onMounted, computed, provide } from "vue"
import ApiService from "@/core/services/ApiService"

import Password from "@/views/general/profile/Password.vue"
import Sessions from "@/views/general//profile/Sessions.vue"
import Edit from "@/views/general/profile/Edit.vue"

export default defineComponent({
  name: "profile",
  components: {
    Password,
    Sessions,
    Edit,
  },
  setup() {
    // const store = useStore()
    const activeName = ref("user-info")

    const userInfo = ref({
      id: "",
      userName: "",
      firstName: "",
      lastName: "",
      email: "",
      tcId: "",
    })

    const GetUserInfo = async () => {
      const { data } = await ApiService.get("user/me")
      userInfo.value = data
    }

    onMounted(async () => {
      await GetUserInfo()
    })

    provide("userInfo", userInfo)

    const fullName = computed(() => {
      return (userInfo.value.firstName + " " + userInfo.value.lastName).toLocaleUpperCase("tr-TR")
    })

    return {
      activeName,
      userInfo,
      fullName,
    }
  },
})
