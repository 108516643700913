
import ApiService from "@/core/services/ApiService"

import { defineComponent, onMounted, ref } from "vue"
import { DateFormatTypes, dateForm } from "@/core/helpers/dateformat"

export default defineComponent({
  name: "account-list",
  components: {},
  setup() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const refreshTokenList = ref<any>([])
    const isLoading = ref(true)

    async function getRefreshTokenList() {
      isLoading.value = true
      const { data } = await ApiService.get("auth/refresh-token")
      refreshTokenList.value = data
      isLoading.value = false
    }

    const revokeToken = async refrestToken => {
      let datum = {
        token: refrestToken,
      }

      await ApiService.post("auth/revoke-token", datum)
      getRefreshTokenList()
    }

    onMounted(async () => {
      await getRefreshTokenList()
    })

    return {
      refreshTokenList,
      revokeToken,
      isLoading,
      DateFormatTypes,
      dateForm,
    }
  },
})
