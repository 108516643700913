
import { ref, defineComponent, computed } from "vue"
import { ElForm } from "element-plus"

export default defineComponent({
  name: "Edit",
  props: ["userInfo"],
  setup(props) {
    type FormInstance = InstanceType<typeof ElForm>
    const ruleFormRef = ref<FormInstance>()

    const userData = computed(() => props.userInfo)
    return {
      ruleFormRef,
      userData,
    }
  },
})
